







































































































































































import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  computed: {
    ...mapGetters(["getCourseDetail", "allConfig", "getUserProfile"])
  },
  mounted() {
    this.$store.dispatch("getUserRoleByCourseId", this.$route.params.courseId);
  },
  methods: {
    logOut() {
      Vue.prototype.$keycloak.logout({
        redirectUri: this.allConfig.studioBaseURL
      });
    },
    redirect(name: string) {
      this.$router.push({ name });
    }
  }
});
