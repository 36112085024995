<template>
  <div class="h-100">
    <Header />
    <div class="main-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from "./main/Header.vue";
export default {
  components: { Header },
  mounted() {
    this.$keycloak.loadUserProfile().then((res) => {
      this.$store.commit("SET_KEYCLOAK_USER_PROFILE", res);
    });
  }
};
</script>

<style lang="scss" scoped>
.main-content {
  background: #f5f5f5;
  min-height: calc(100% - 76px);
}
</style>
